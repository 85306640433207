import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { EmptyState, Tabs, Card, ResourceList, Badge, Pagination, Stack, TextStyle, Button } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import ErrorPage from '../Error/ErrorPage.jsx';
import AppPage from '../AppPage/AppPage.jsx';
import FeedbackModal from '../FeedbackModal/FeedbackModal.jsx';
import EventFilters from './EventFilters';
import EventListEmptyState from './EventListEmptyState';
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import ExportAttendeesModal from './ExportAttendeesModal';
import ExportEventsModal from './ExportEventsModal';
import EventListResourceItem from './EventListResourceItem';
import { GET_EVENTS } from '../../utils/graphql';
import { hasNewPricing, hasImportEvents } from '../../utils/features';
import { currentShopDomain } from '../../utils/auth';
import { storageGetItem, storageSetItem } from '../../utils/storage';
import { ImportMinor } from '@shopify/polaris-icons';
import ArchiveEventsModal from './ArchiveEventsModal';

const PER_PAGE = 20;

const EventList = () => {
  const [selectedTab, setSelectedTab] = React.useState((new URLSearchParams(window.location.search)).get('category'));
  const [sortValue, setSortValue] = React.useState(storageGetItem('EVEY_EVENTS_SORT') || '-date');
  const [category, setCategory] = React.useState((new URLSearchParams(window.location.search)).get('category'));
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showExportAttendeesModal, setShowExportAttendeesModal] = React.useState(false);
  const [showExportEventsModal, setShowExportEventsModal] = React.useState(false);
  const [showArchiveEventsModal, setShowArchiveEventsModal] = React.useState(false);

  const { showToastNotice, loadingMarkup, canCreateEvents, canViewAttendees, isVendor } = useFrame();
  const history = useHistory();

  const showTicketBundles = false;
  const newPricingEnabled = hasNewPricing(currentShopDomain());
  const segmentIdentify = async (shop) => {
    let obj = {
      id: shop.id,
      data: {
        email: shop.email,
        firstname: shop.firstName,
        lastname: shop.lastName,
        phone: shop.phone,
        shopify_url: shop.domain,
        evey_plan: shop.activeSubscription?.plan?.hubspotRef,
        company: {
          name: shop.name
        },
        address: {
          country: shop.countryCode,
        }
      }
    };
    return new Promise((resolve, reject) =>{
      resolve(obj)
    })
  }

  const secondaryActions = [
    {
      content: 'Help',
      url: 'https://evey-events.zendesk.com/hc/en-us/',
      target: 'REMOTE',
      external: true
    },
    ...(isVendor ? [] : [{
      content: 'Settings',
      target: 'APP',
      onAction: () => goToPage({ history, path: '/settings' }),
    }]),
  ];

  return (
    <Query
      query={GET_EVENTS}
      variables={{
        'category': category,
        'sort': sortValue,
        'filters': JSON.stringify(appliedFilters),
        'search': searchValue,
        'page': currentPage,
        'perPage': PER_PAGE
      }}
      onCompleted={(data) => {
        if (data && data.currentShop && data.currentShop.askForFeedback) {
          setShowFeedbackModal(true);
        }
      }}
    >
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;

        const currentShop = data && data.currentShop;
        let eventList = [];
        let eventsCount = 0;
        let totalEventsCount = 0;
        let eventTags = [];

        let showEventStatus = false;

        if (!loading) {
          segmentIdentify(data.currentShop).then((obj) => {
            analytics.identify('evey-' + obj.id, obj.data)
            return obj
          });

          eventList = [...currentShop.events];
          eventsCount = currentShop.eventsCount;
          totalEventsCount = currentShop.totalEventCount;
          eventTags = currentShop.eventTags;

          if (totalEventsCount == 0) {
            return <EventListEmptyState secondaryActions={secondaryActions} />;
          }

          showEventStatus = !newPricingEnabled || (currentShop.hasLegacyPricing && !currentShop.activeSubscription);
        }

        const tabs = [
          {
            id: 'all-events',
            content: 'All Events',
            accessibilityLabel: 'All events',
            panelID: 'all-events-content'
          },
          {
            id: 'upcoming-events',
            content: 'Upcoming Events',
            accessibilityLabel: 'Upcoming events',
            panelID: 'upcoming-events-content'
          },
          ...(showEventStatus ? [{
            id: 'subscription-active-events',
            content: 'Active Subscriptions',
            accessibilityLabel: 'Active subscription events',
            panelID: 'subscription-active-events-content'
          }] : []),
          ...(showTicketBundles ? [{
            id: 'ticket-bundles',
            content: 'Ticket Bundles',
            accessibilityLabel: 'Ticket bundles',
            panelId: 'ticket-bundles-content',
          }] : []),
          ...((currentShop?.archivedEventsCount && currentShop?.archivedEventsCount > 0) ? [{
            id: 'archived-events',
            content: 'Archived Events',
            accessibilityLabel: 'Archived events',
            panelID: 'archived-events-content'
          }] : []),
        ];

        const handleTabChange = (selectedTabIndex) => {
          const tab = tabs[selectedTabIndex];
          setSelectedTab(tab?.id || 0);
          setCategory(tab?.id)
          setCurrentPage(1);
          history.push(`/events?category=${tab?.id || ''}`);
        };

        return (
          <AppPage
            title="Events"
            primaryAction={canCreateEvents ? {
              content: 'Create event',
              onAction: () => goToPage({ history, path: '/events/new' }),
            } : undefined}
            secondaryActions={secondaryActions}
          >
            <Card
              actions={[
                ...(showTicketBundles && canCreateEvents ? [
                  {
                    content: 'Create ticket bundle',
                    target: 'APP',
                    onAction: () => goToPage({ history, path: '/bundles/new' }),
                  }
                ] : []),
                ...(currentShop && canCreateEvents && hasImportEvents(currentShop.domain) ? [{
                    content: 'Import events',
                    onAction: () => {

                    },
                    icon: ImportMinor,
                  }] : []),
              ]}
            >
              <Tabs tabs={tabs} selected={tabs.findIndex((t) => t.id == selectedTab) === -1 ? 0 : tabs.findIndex((t) => t.id == selectedTab)} onSelect={handleTabChange}>
                <ResourceList
                  resourceName={{ singular: 'event', plural: 'events' }}
                  items={eventList}
                  loading={loading}
                  hasMoreItems={eventsCount > PER_PAGE}
                  showHeader={true}
                  sortOptions={[
                    { label: 'Newest start date', value: '-date' },
                    { label: 'Oldest start date', value: 'date' },
                    { label: 'High number', value: '-number' },
                    { label: 'Low number', value: 'number' },
                    { label: 'Title A-Z', value: '-name' },
                    { label: 'Title Z-A', value: 'name' }
                  ]}
                  sortValue={sortValue}
                  onSortChange={(v) => {
                    setSortValue(v);
                    setCurrentPage(1);
                    storageSetItem('EVEY_EVENTS_SORT', v);
                  }}
                  filterControl={
                    <EventFilters
                      filters={{
                        search: searchValue,
                        appliedFilters: appliedFilters,
                      }}
                      shop={currentShop}
                      onUpdate={({ search, filters }) => {
                        setSearchValue(search);
                        setAppliedFilters(filters);
                        setCurrentPage(1);
                      }}
                      includeStatusFilter={showEventStatus}
                    />
                  }
                  renderItem={(event) => {
                    return (
                      <EventListResourceItem event={event} showStatusBadge={showEventStatus} />
                    );
                  }}
                  selectedItems={selectedItems}
                  onSelectionChange={setSelectedItems}
                  bulkActions={[
                    ...(canViewAttendees ? [{ content: 'Export attendees', onAction: () => setShowExportAttendeesModal(true) }] : []),
                    { content: 'Export events', onAction: () => setShowExportEventsModal(true) },
                    { content: 'Archive events', onAction: () => setShowArchiveEventsModal(true) },
                  ]}
                />
                {eventList.length > 0 &&
                <div className="EventListFooter">
                  <Pagination
                    hasPrevious={currentPage > 1}
                    hasNext={currentPage < Math.ceil(eventsCount / PER_PAGE)}
                    onPrevious={() => setCurrentPage(currentPage - 1)}
                    onNext={() => setCurrentPage(currentPage + 1)}
                  />
                </div>
                }
              </Tabs>
            </Card>
            <FeedbackModal
              open={showFeedbackModal}
              onClose={() => setShowFeedbackModal(false)}
            />
            {showExportAttendeesModal &&
              <ExportAttendeesModal
                shop={currentShop}
                eventIds={selectedItems === 'All' ? null : selectedItems}
                category={category}
                search={searchValue}
                filters={appliedFilters}
                onClose={() => setShowExportAttendeesModal(false)}
              />
            }
            {showExportEventsModal &&
              <ExportEventsModal
                shop={currentShop}
                eventIds={selectedItems === 'All' ? null : selectedItems}
                eveyEventIds={selectedItems === 'All' ? null : selectedItems.map((id) => eventList.find((event) => event.id == id)?.eveyEventId).filter((id) => id)}
                category={category}
                search={searchValue}
                filters={appliedFilters}
                onClose={() => setShowExportEventsModal(false)}
              />
            }
            {showArchiveEventsModal &&
              <ArchiveEventsModal
                shop={currentShop}
                eventIds={selectedItems === 'All' ? null : selectedItems}
                category={category}
                search={searchValue}
                filters={appliedFilters}
                onClose={() => setShowArchiveEventsModal(false)}
              />
            }
          </AppPage>
        );
      }}
    </Query>
  );
}

export default EventList;
