import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Modal,
  FormLayout,
  TextField,
  Banner,
  Checkbox,
  Collapsible,
  Autocomplete,
  ChoiceList,
  Stack,
  Link,
  Subheading,
} from '@shopify/polaris';
import { useState } from 'react';
import { formatErrors, reportError } from '../../utils/errors';
import { CHECKIN_HOST } from '../../utils/checkinHost';
import { EDIT_CHECKIN_USER, EDIT_CHECKIN_USER_PERMISSIONS, DELETE_CHECKIN_USER } from '../../utils/graphql';
import ModalFooter from '../shared/ModalFooter.jsx';
import { useFrame } from '../../contexts/frame';

const EditCheckinUserModal = ({ user, onClose, events }) => {
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [fullAccess, setFullAccess] = useState(user.fullAccess);
  const [selectedEvents, setSelectedEvents] = useState(user.permissions.map((p) => p.eventId));
  const [eventsInputText, setEventsInputText] = useState('');
  const [saving, setSaving] = useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const forgotPasswordUrl = `${CHECKIN_HOST}/forgot_password`;

  const isValid = !password.length && !passwordConfirm.length || passwordConfirm.length && password == passwordConfirm;

  return (
    <Mutation mutation={EDIT_CHECKIN_USER_PERMISSIONS}
      onError={() => {
        showToastError('Failed to update user');
      }}
      onCompleted={(data) => {
        if (data && data.editCheckinUserPermissions) {
          showToastNotice('User updated');
          onClose();
        }
      }}
      refetchQueries={() => ['CheckinSettingsQuery']}
    >
      {(editCheckinUserPermissions) => (
        <Mutation mutation={EDIT_CHECKIN_USER}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              setErrors(error.graphQLErrors[0].errors);
            } else {
              setErrors(['There was a problem updating this user. Contact us if this problem persists.']);
              reportError(error);
            }
          }}
          onCompleted={(data) => {
            if (data && data.editCheckinUser) {
              let permissions = selectedEvents.map((id) => ({ event_id: id }));
              editCheckinUserPermissions({
                variables: {
                  userId: user.id,
                  permissions: JSON.stringify(permissions),
                }
              });
            }
          }}
          refetchQueries={() => ['CheckinSettingsQuery']}
        >
          {(editCheckinUser) => (
            <Mutation mutation={DELETE_CHECKIN_USER}
              onError={() => {
                showToastError('Failed to delete user');
              }}
              onCompleted={(data) => {
                if (data && data.deleteCheckinUser) {
                  showToastNotice('User deleted');
                  onClose();
                }
              }}
              refetchQueries={() => ['CheckinSettingsQuery']}
            >
              {(deleteCheckinUser, { loading: deleting }) => (
                <Modal
                  open={true}
                  onClose={() => onClose()}
                  title={'Update check-in user'}
                  footer={
                    <ModalFooter
                      saveButtonText="Save"
                      saveButtonAction={async () => {
                        setErrors([]);
                        setSaving(true);
                        editCheckinUser({
                          variables: {
                            userId: user.id,
                            password,
                            passwordConfirm,
                            fullAccess,
                          }
                        });
                      }}
                      saveButtonLoading={saving}
                      saveButtonDisabled={saving || deleting || !isValid}
                      cancelButtonAction={onClose}
                      cancelButtonDisabled={saving || deleting}
                      destructiveButtonLoading={deleting}
                      destructiveButtonText={'Delete'}
                      destructiveButtonAction={async () => {
                        setErrors([]);
                        deleteCheckinUser({
                          variables: {
                            userId: user.id,
                          }
                        });
                      }}
                      destructiveButtonDisabled={saving || deleting}
                    />
                  }
                >
                  {errors && errors.length > 0 &&
                    <Modal.Section>
                      <Banner
                        title="There was a problem updating the user"
                        status="critical"
                      >
                        {formatErrors(errors)}
                      </Banner>
                    </Modal.Section>
                  }
                  <Modal.Section>
                    <FormLayout>
                      <TextField
                        label='Email'
                        type='email'
                        value={user.email}
                        readOnly
                        helpText=<span>To reset your password, go to the <Link external url={forgotPasswordUrl}>forgot password</Link> page.</span>
                      />
                      {/*
                      <TextField
                        label='Password (blank to keep the same)'
                        type='password'
                        value={password}
                        onChange={(newValue) => setPassword(newValue)}
                      />
                      <TextField
                        label='Confirm password'
                        type='password'
                        value={passwordConfirm}
                        onChange={(newValue) => setPasswordConfirm(newValue)}
                      />
                      */}
                    </FormLayout>
                  </Modal.Section>
                  <Modal.Section>
                    <FormLayout>
                      <Subheading>Permissions</Subheading>
                      <Checkbox
                        checked={fullAccess}
                        label="Full check-in access to all events"
                        onChange={(newValue) => setFullAccess(newValue)}
                      />
                      <Collapsible open={!fullAccess}>
                        <Stack vertical={true} spacing='loose'>
                          <Autocomplete
                            allowMultiple
                            options={events.map((e) => ({ value: e.eveyEventId, label: e.title }))}
                            selected={selectedEvents}
                            textField=<Autocomplete.TextField
                              onChange={(newValue) => setEventsInputText(newValue)}
                              label="Select the events this account should have check-in access for"
                              value={eventsInputText}
                              placeholder="Event title"
                              helpText=""
                            />
                            onSelect={(newValue) => setSelectedEvents(newValue)}
                            listTitle="Events"
                          />
                          <ChoiceList
                            title=""
                            allowMultiple={true}
                            choices={selectedEvents.filter((selected) => events.some((e) => e.eveyEventId == selected)).map((selected) => ({
                              value: selected,
                              label: events.find((e) => e.eveyEventId == selected).title,
                            }))}
                            selected={selectedEvents}
                            onChange={(newValue) => setSelectedEvents(newValue)}
                          />
                        </Stack>
                      </Collapsible>
                    </FormLayout>
                  </Modal.Section>
                </Modal>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default EditCheckinUserModal;
